@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '../../../variables';

$offsetValue: -15%;
$numberTopOffsetValue: -30%;
$numberHorizontalOffsetValue: -10%;

.cardContainer {
    --offset-value: -15%;
    --number-top-offset-value: -30%;
    --number-horizontal-offset-value: -10%;

    position: relative;
    width: 850px;
    height: 480px;
    box-shadow: 4px 16px 64px rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: box-shadow 300ms;
    z-index: 1;

    &:hover {
        box-shadow: 4px 16px 96px rgba(0, 0, 0, 0.45);

        .cardImage::before {
            opacity: 1; }

        .cardNumber {
            transform: translateY(0);
            opacity: 1; } }

    .cardImage {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 1em 2em;

        &::before {
            content: "";
            opacity: 0;
            transition-duration: 250ms;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5); } } }

.cardContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    gap: 1em;

    .cardNumber {
        text-shadow: 4px 16px 64px rgba(199, 81, 81, 0.45);
        transition-duration: 500ms;
        transform: translateY(15%);
        opacity: 0;
        top: var(--number-top-offset-value);
        font-size: 10rem;
        font-weight: bold;
        color: $menu-underline;
        position: absolute;
        font-family: 'Poppins', sans-serif; }

    .cardTitle {
        color: $logo-color;
        font-weight: bold;
        font-size: 2.5rem; }

    .cardDescription {
        color: white;
        font-size: 1.5rem;
        max-width: 60%; }

    .cardButtonContainer {
        display: flex;
        gap: 1.5em;

        .cardButton {
            font-size: 2.25rem;
            color: $logo-color;
            transition: transform ease-in 150ms;
            position: relative;

            &:hover {
                color: $logo-color-hovered;
                transform: scale(1.1);

                &::after {
                    transform: scale(1);
                    top: 100%; } }

            &::after {
                content: attr(data-tooltip);
                display: block;
                position: absolute;
                background-color: rgba(black, .7);
                padding: 1em 2em;
                color: white;
                border-radius: 5px;
                font-size: 0.8rem;
                top: 0;
                left: -125%;
                white-space: nowrap;
                transform: scale(0);
                transition: transform ease-out 150ms, top ease-out 150ms; } } } }

.cardOdd {
    left: var(--offset-value);

    .cardContent {
        align-items: flex-end;

        .cardNumber {
            left: var(--number-horizontal-offset-value); }

        .cardDescription {
            text-align: right; } } }

.cardEven {
    right: var(--offset-value);

    .cardContent {
        align-items: flex-start;

        .cardNumber {
            right: var(--number-horizontal-offset-value); }

        .cardDescription {
            text-align: left; } } }

@media screen and ( max-width: 1280px ) {
    .cardContainer {
        width: 700px;
        height: 393px; }

    .cardContent {
        gap: 0.8em;

        .cardNumber {
            font-size: 8rem; }

        .cardTitle {
            font-size: 2rem; }

        .cardDescription {
            font-size: 1.25rem; } } }

@media screen and ( max-width: 1024px ) {
    .cardContainer {
        --offset-value: 0; } }

@media screen and ( max-width: 768px ) {
    .cardContainer {
        width: 550px;
        height: 309px;

        .cardImage {
            padding: 1em; }

        .cardContent {
            gap: 0.7em;

            .cardNumber {
                font-size: 6rem; }

            .cardTitle {
                font-size: 1.75rem; }

            .cardDescription {
                font-size: 1rem; }

            .cardButton {
                font-size: 1.5rem;

                &::after {
                    font-size: 0.5rem; } } } } }

@media screen and ( max-width: 640px ) {
    .cardContainer {
        width: 450px;
        height: 253px;
        --number-horizontal-offset-value: -7.5%;
        box-shadow: 2px 8px 48px rgba(0, 0, 0, 0.45);

        &:hover {
            box-shadow: 2px 8px 56px rgba(0, 0, 0, 0.45); }

        .cardContent {
            gap: 0.5em;

            .cardNumber {
                font-size: 6rem; }

            .cardTitle {
                font-size: 1.25rem; }

            .cardDescription {
                font-size: 0.75rem; }

            .cardButton {
                font-size: 1.25rem; }

            .cardButtonContainer {
                gap: 1em; } } } }

@media screen and ( max-width: 480px ) {
    .cardContainer {
        width: 320px;
        height: 182px;

        .cardContent {
            gap: 0.5em;

            .cardNumber {
                display: none; }

            .cardTitle {
                font-size: 1.25rem; }

            .cardDescription {
                font-size: 0.75rem; }

            .cardButton {
                font-size: 1.25rem; } } } }

@media screen and ( max-width: 320px ) {
    .cardContainer {
        width: 250px;
        height: 182px; } }
