@import '../../../variables';

.menuContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;

    .linkContainer {
        position: relative;
        z-index: 10;
        list-style: none;
        display: flex;
        padding-top: 8em;
        padding-left: 1.5em;
        flex-direction: column;
        align-items: flex-start;
        gap: 2em;
        pointer-events: all;

        * + * {
            margin-left: 0em; }

        li a {
            font-size: 1.25rem;
            color: $menu-color;
            transition-duration: 300ms;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: left; } }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: -4px;
                height: 2px;
                background-color: $menu-underline;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 200ms; } } }

    &::before, &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition: transform .45s ease-in-out,-webkit-transform .45s ease-in-out; }

    &::before {
        z-index: 5;
        background-color: $logo-color;
        transition-delay: 300ms; }

    &::after {
        z-index: 8;
        background: linear-gradient(90deg, rgba(12,14,4,1) 0%, rgba(21,22,13,1) 36%, rgba(26,27,18,1) 62%, rgba(30,31,23,1) 79%, rgba(42,43,35,1) 100%);
        transition-delay: 200ms; } }

.menuContainerScrolled {
    &::before {
        background-color: $logo-color-scrolled; }

    &::after {
        background: white; }

    .linkContainer li a {
        color: black; }

    .contactContainer li a:hover {
        color: $logo-color-scrolled; } }

.contactContainer {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: auto 0 1.5em 1.5em;
    z-index: 10;
    position: relative;
    gap: 2em;

    li a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: darkgray;
        font-size: 2.5rem;
        transition-duration: 200ms;

        &:hover {
            color: $logo-color; } } }

.menuPopupItem {
    transition-duration: 400ms;
    transition-delay: 0ms;
    opacity: 0;
    transform: translateY(60%); }

.menuContainerOpen {
    transform: translateX(0%);

    .menuPopupItem {
        opacity: 1;
        transform: translateY(0%);
        transition-delay: 400ms; }

    &::before {
        transform: translateX(0%);
        transition-delay: 0ms; }

    &::after {
        transform: translateX(0%);
        transition-delay: 200ms; } }
