@import "../../variables";

.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5em 25%;
    background-color: #f1f1f1;
    scroll-margin: 90px; }

.projectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5em 3em 10em;
    flex-direction: column;
    border: 1px #e1e1e1 solid;
    position: relative;
    gap: 5em;
    background-color: inherit;

    .title {
        top: -0.75em;
        left: auto;
        right: auto;
        position: absolute;
        padding: 0 1em;
        width: max-content;
        color: $title-color;
        font-size: 2.25rem;
        font-weight: bold;
        background-color: inherit; } }

.seeMoreBtn {
    color: $menu-underline;
    font-size: 1.75rem;
    transition-duration: 250ms;
    font-weight: bold;
    padding: 0.6em 1.2em;
    margin-top: 0.5em;
    cursor: pointer;
    position: absolute;
    bottom: -1.2em;
    left: auto;
    right: auto;
    background-color: inherit;

    &:hover {
        color: #b62d2d; } }

@media screen and ( max-width: 1280px ) {
    .sectionContainer {
        padding: 5em 15%; } }

@media screen and ( max-width: 640px ) {
    .sectionContainer {
        scroll-margin: 24px; }

    .projectContainer {
        gap: 3em;
        padding: 2em 3em;

        .title {
            font-size: 1.5rem; }

        .seeMoreBtn {
            font-size: 1rem; } } }
