@import "../../variables";

.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0em 10% 5em;
    background-color: #f1f1f1;
    scroll-margin: 90px; }

.achievementContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5em 3em;
    flex-direction: column;
    position: relative;
    gap: 5em;
    background-color: inherit;

    .title {
        padding: 0 1em;
        color: $title-color;
        font-size: 2.25rem;
        font-weight: bold;
        background-color: inherit; } }

.cardContainer {
    display: grid;
    width: 100%;
    gap: 2em;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }

@media screen and ( max-width: 640px ) {
    .sectionContainer {
        padding: 0em 10% 5em;
        scroll-margin: 64px; }

    .achievementContainer {
        padding: 2em 0em;
        gap: 2em;

        .title {
            font-size: 1.5rem; } } }

@media screen and ( max-width: 480px ) {
    .cardContainer {
        gap: 3em;
        grid-template-columns: repeat(auto-fill, minmax(175px, 1fr)); } }
