@import '../../../variables';

.imageContainer {
    display: flex;
    position: relative;

    &:hover {
        .achievementContent {
            background-color: rgba(0, 0, 0, 0.8);

            span {
                transform: translateY(0);
                opacity: 1; } } }

    .achievementImage {
        display: flex;
        width: 100%; }

    .achievementContent {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        padding: 1em 2em;
        transition: background-color ease-in 250ms;

        span {
            color: $logo-color;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
            transition: transform ease-in 250ms, opacity ease-in 250ms;
            transform: translateY(-50%);
            opacity: 0; } } }
