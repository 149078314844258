@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import '../../variables';

$mobilePadding: 0.5em 1.5em;

.headerContainer {
    --background-color: transparent;
    --logo-color: #FEFEDF;
    --links-color: #FFFFFF;

    background-color: var(--background-color);
    padding: 1em 8em 1em 2.5em;
    display: flex;
    align-self: center;
    justify-content: space-between;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    transition-duration: 300ms; }

.logoContainer {
    font-family: 'Kaushan Script', cursive;
    color: var(--logo-color);
    font-size: 2.5rem; }

nav {
    display: flex;
    align-items: center;

    .linkContainer {
        list-style: none;
        display: flex;

        * + * {
            margin-left: 1.5em; }

        li a {
            color: var(--links-color);
            transition-duration: 300ms;
            font-size: 0.9rem;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: left; } }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: -4px;
                height: 2px;
                background-color: $menu-underline;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform 200ms ease-in; } } } }

.navMenuContainerResponsive, .navMenuPopupContainer {
    display: none; }

@media screen and ( max-width: 1280px ) {
    .headerContainer {
        padding: 1em 2.5em 1em 2.5em; } }

@media screen and ( max-width: 768px ) {
    .logoContainer {
        font-size: 2rem; }

    nav .linkContainer li a {
        font-size: 0.8rem; } }

@media screen and ( max-width: 640px ) {
    .headerContainer {
        padding: $mobilePadding; }

    .logoContainer {
        font-size: 1.75rem; }

    nav .linkContainer li a {
        font-size: 0.65rem; } }

@media screen and ( max-width: 480px ) {
    .navMenuContainer {
        display: none; }

    .navMenuContainerResponsive, .navMenuPopupContainer {
        display: flex; } }

.headerScrolled {
    --background-color: white;
    --logo-color: #204374;
    --links-color: black;

    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1); }
